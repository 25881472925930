<template>
    <div class="wrap">
        <div class="box">
            <div class="top ">
                <div class="img-box">
                    <el-image class="img" :src="rImg(m.image || ((m.server && m.server.head_portrait) ? m.server.head_portrait :'' ))" fit="cover" :preview-src-list="rBigImg([m.image])">
                        <div slot="error" class="image-slot"><i class="el-icon-picture-outline"></i></div>
                    </el-image>
                </div>
                <div class="content">
                    <h3 class="title">{{ m.title }}</h3>
                    <p class="price">{{ m.type == 1 ? '线下' : '线上' }} · ￥{{ m.price }}/小时</p>
                    <div class="tag-box">
                        <div class="tag" :key="c.id" v-for="c in m.category">{{ c.title }}</div>
                    </div>
                    <div class="user" style="margin-top: 30px;" v-if="m.server">
                        <img :src="rImg(m.server.head_portrait)" :onerror="txImg" class="tx" alt="" />
                        <span class="name ellipsis-1">{{ m.server.name }}</span>
                    </div>
                </div>
            </div>
            <!-- <div class="info-box">
        <div class="info">
          <span class="name">时长</span>
        </div>
      </div> -->
            <payList @setPay="setPay"></payList>
            <div class="bottom flex-c">
                <el-button round class="app-btn" @click="submit()">立即下单</el-button>
            </div>
        </div>
        <h3 class="box-title">服务描述</h3>
        <div class="box">
            <pre class="des">{{ m.content }}</pre>
        </div>
        <h3 class="box-title">下单特权</h3>
        <div class="box">
            <p>{{ m.type == 1 ? '服务结束前是免费语音和文字聊天的' : '一小时内是免费文字聊天和语音的' }}</p>
        </div>
        <h3 class="box-title">服务流程</h3>
        <div class="box">
            <div class="safe-box service-box">
                <div class="safe" v-if="m.type == 1">
                    <p class="txt">填写信息</p>
                    <i class="iconfont icon-tianxie"></i>
                </div>
                <i class="el-icon-arrow-right" v-if="m.type == 1"></i>
                <div class="safe">
                    <p class="txt">支付金额</p>
                    <i class="iconfont icon-qianbao"></i>
                </div>
                <i class="el-icon-arrow-right"></i>
                <div class="safe">
                    <p class="txt">服务方确认</p>
                    <i class="iconfont icon-querendan-"></i>
                </div>
                <i class="el-icon-arrow-right"></i>
                <div class="safe">
                    <p class="txt">付款方确认</p>
                    <i class="iconfont icon-querenfangan"></i>
                </div>
                <i class="el-icon-arrow-right"></i>
                <div class="safe">
                    <p class="txt">订单完成</p>
                    <i class="iconfont icon-queren"></i>
                </div>
            </div>
        </div>
        <h3 class="box-title">平台保障</h3>
        <div class="box">
            <div class="safe-box">
                <div class="safe">
                    <i class="iconfont icon-danganziliao-ziliaoqingdan"></i>
                    <p class="txt">真实资料</p>
                    <p class="txt-des">实名认证 真实可靠</p>
                </div>
                <div class="safe">
                    <i class="iconfont icon-anquan-"></i>
                    <p class="txt">资金安全</p>
                    <p class="txt-des">平台监督 保障安全</p>
                </div>
                <div class="safe">
                    <i class="iconfont icon-kefu"></i>
                    <p class="txt">在线客服</p>
                    <p class="txt-des">在线客服 全程服务</p>
                </div>
            </div>
        </div>
        <h3 class="box-title">温馨提示</h3>
        <div class="box" v-html="des"></div>
    </div>
</template>

<script>
    import payList from "@components/common/pay";
    export default {
        components: {
            payList,
        },
        data() {
            return {
                id: "",
                des: "",
                method: 2, // 2支付宝 3微信
                m: {},
            };
        },
        created() {
            // 在组件实例处理完所有与状态相关的选项后调用。
            this.id = this.$route.query.id;
            this.getData();
            this.getPage();
        },
        mounted() {
            // 在组件被挂载之后调用。
        },
        methods: {
            setPay(t) {},
            // 获取详情
            async getData() {
                let {
                    data
                } = await this.$y_ajax("api/service/info", {
                    id: this.id,
                    user_id: this.$y_getKey("userid"),
                });
                this.m = data;
            },
            // 单页内容 温馨提示
            async getPage() {
                let {
                    data
                } = await this.$y_ajax("api/page/view", {
                    id: 87
                });
                this.des = data.body;
            },
            // 下单
            async submit() {
                // let { data } = await this.$y_ajax('api/service/CreateOrder', { id: 34 });
                // this.des = data.body;
                this.$y_msg("该服务暂未开放");
            },
        },
        computed: {
            // 计算属性
        },
        watch: {
            // 监听
        },
    };
</script>

<style lang="less" scoped>
    @import "@assets/less/des.less";

    .price {
        margin: 10px 0;
        color: #fd5621;
    }

    .tag-box {
        .tag {
            background: transparent;
            color: #fd5621;
            border: 1px solid;
            margin-right: 5px;
        }
    }

    .des {
        word-break: break-all;
        white-space: pre-wrap;
        word-wrap: break-word;
        font-size: 16px;
        line-height: 1.5;
    }
</style>